import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { navigate } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function ContactPage() {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <Layout>
      <SEO
        title="Kontakt"
        description={`Använd vårt kontaktformulär för frågor, feedback eller samarbete. Tveka inte att höra av dig!`}
      />
      <div className="article-container">
        <h1 className="section-title">Kontakt</h1>
        <p>
          För frågor, feedback eller samarbete, tveka inte att höra av dig via
          kontaktformuläret nedan
        </p>
        <form
          name="contact"
          method="post"
          action="/tack/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />

          <p>
            <label>
              <strong>Ditt namn</strong>{" "}
              <input type="text" name="name" onChange={handleChange} required />
            </label>
          </p>
          <p>
            <label>
              <strong>Din email</strong>{" "}
              <input
                type="email"
                name="email"
                onChange={handleChange}
                required
              />
            </label>
          </p>
          <p>
            <label>
              <strong>Meddelande</strong>{" "}
              <textarea
                name="message"
                rows="4"
                onChange={handleChange}
                required
              ></textarea>
            </label>
          </p>
          <p>
            <button type="submit">Skicka</button>
          </p>
        </form>
      </div>
    </Layout>
  )
}
